import { render, staticRenderFns } from "./ItemGroup.vue?vue&type=template&id=57f8b54c&scoped=true&"
import script from "./ItemGroup.vue?vue&type=script&lang=js&"
export * from "./ItemGroup.vue?vue&type=script&lang=js&"
import style0 from "./ItemGroup.vue?vue&type=style&index=0&id=57f8b54c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f8b54c",
  null
  
)

export default component.exports